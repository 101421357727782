import React from "react";
import PostCreationFrom from "../components/PostCreationForm";

function CreatePost() {
    return (
        <div className="mt-10">
            <PostCreationFrom />
        </div>
    );
}

export default CreatePost;
